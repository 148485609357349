import { ApiProduct } from 'app/home/infrastructure/api/response/ApiProduct';
import { ApiProductCatalog } from '../../../../../product-catalog-collections/Catalog/shared/domain/ApiProductCatalog';
import { Packing } from '../../../../../products/domain/model/Packing';
import { CollectionId } from '../../../../../products/domain/value-object/CollectionId';
import { CollectionName } from '../../../../../products/domain/value-object/CollectionName';
import { Image } from '../../../../../products/domain/value-object/Image';
import { ProductFormat } from '../../../../../products/domain/value-object/ProductFormat';
import { ProductId } from '../../../../../products/domain/value-object/ProductId';
import { ProductPrice } from '../../../../../products/domain/value-object/ProductPrice';

export class PackingMapper {

    static fromApiProductCatalog(response: ApiProductCatalog): Packing {
        const price = response.price ? new ProductPrice(
            response.price.value,
            response.price.quality,
            response.price.discount,
            response.price.palletPrice,
        ) : null;
        return new Packing(
            new CollectionId(response.collectionId),
            new ProductId(response.product),
            new Image(response.image),
            new CollectionName(response.collection),
            new ProductFormat(response.format),
            response.description,
            response.boxMeter,
            response.boxMeterL,
            response.boxPiece,
            response.boxWeight,
            response.default,
            response.palletBox,
            response.palletPlane,
            response.palletPiece,
            response.palletMeter,
            response.heights,
            response.family,
            response.code,
            response.outlet,
            response.stockEntryDate ? new Date(response.stockEntryDate) : null,
            response.tile,
            null,
            response.code,
            response.unit,
            response.paste,
            response.enabled ? response.enabled : false,
            price,
        );
    }

    static fromApiProduct(response: ApiProduct): Packing {
        return new Packing(
            response.collectionId ? new CollectionId(response.collectionId) : null,
            new ProductId(response.product),
            new Image(response.image),
            new CollectionName(response.collection),
            new ProductFormat(response.format),
            response.description,
            response.boxMeter,
            response.boxMeterL,
            response.boxPiece,
            response.boxWeight,
            response.default,
            response.palletBox,
            response.palletPlane,
            response.palletPiece,
            response.palletMeter,
            response.heights,
            response.family,
            response.code,
            response.outlet,
            response.stockEntryDate ? new Date(response.stockEntryDate) : null,
            response.tile,
            null,
            response.code,
            response.unit,
            response.paste,
        );
    }

}
