import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { ICartRepository } from '../../domain/repository/CartRepository';
import Cart from '../../../cart/domain/model/Cart';
import CartMapper from '../../../cart/infraestructure/repository/api/data-mapper/CartMapper';
import { TransportTypeMapper } from '../../../shared/infrastructure/tencer-api/data-mapper/transport-type.mapper';

export class ApiCartRepository implements ICartRepository {

    private readonly _client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this._client = client;
    }

    public async confirm(cart: Cart): Promise<void> {
        if (cart.transport) {
            const transport = cart.transport;
            await this._client.patchMyCartShipping({
                transport_type: TransportTypeMapper.fromDomain(transport.type),
                lift_gate: transport.liftGate ?? false,
                consolidated: transport.isConsolidated,
                commentary: transport.comments ?? undefined,
                courier: transport.courier?.id.value ?? undefined,
                courier_service: transport.courierService?.id.value ?? undefined,
                account_number: transport.account ?? undefined,
            });
        }

        if (cart.creditMemo) {
            const creditMemo = cart.creditMemo;
            await this._client.patchMyCartPayment(
                {
                    credit_memo_id: creditMemo?.id,
                },
            );
        }

        return this._client.postMyCartCheckout();

    }

    public async getMyCart(): Promise<Cart | null> {
        const response = await this._client.getMyCart();
        return CartMapper.fromResponse(response);
    }

    public async checkout(): Promise<void> {
        return this._client.postMyCartCheckout();
    }

    public async release(): Promise<void> {
        return this._client.postMyCartRelease();
    }

}
