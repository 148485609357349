import React, { ReactElement, useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
    CART_IN_PAYMENT_GATEWAY_STATUS,
    CART_IN_PAYMENT_GATEWAY_STATUS_INFO,
    LOADING,
    RELEASE_CART,
} from '../../../i18n/translations/TR';
import { CActionButton } from '../../shared/components/CActionButton/CActionButton';
import { DependencyInjectionContext } from '../../shared/context/DependecyInjectionContext';

interface CartInPaymentGatewayModalProps {
    showModal: boolean
    closeModal: () => void
}

export const CartInPaymentGateway = (props: CartInPaymentGatewayModalProps): ReactElement => {
    const { t } = useTranslation();
    const dic = useContext(DependencyInjectionContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleReleaseCart = async (): Promise<void> => {
        setIsLoading(true);

        return dic.cartRepository.release()
            .then((): void => {
                setIsLoading(false);
                props.closeModal();
            });
    };

    return (
        <Modal
            centered
            show={props.showModal}
            className="modal fade shopping-modal"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
                <h5 className="modal-title">{t(CART_IN_PAYMENT_GATEWAY_STATUS)}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>{t(CART_IN_PAYMENT_GATEWAY_STATUS_INFO)}</div>
            </Modal.Body>
            <Modal.Footer>
                <CActionButton
                    isBusy={isLoading}
                    disabled={isLoading}
                    text={t(RELEASE_CART)}
                    textIsBusy={t(LOADING)}
                    onClick={handleReleaseCart}
                />
            </Modal.Footer>
        </Modal>
    );
};
