import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoWowBlack } from 'assets/img/logoWowBlack.svg';
import { useIsMounted } from '../../../../shared/hooks/useIsMounted';
import { CartPayment } from '../../components/payment/CartPayment';
import { useCartPayment } from '../../hooks/useCartPayment';
import { UserCredentialId } from '../../../../shared/domain/value-objects/UserCredentialId';
import { useToken } from '../../../../shared/hooks/use-token';
import { CREDIT_CARD_PAYMENT_TITLE } from '../../../../../i18n/translations/TR';
import { TencerApiClientSingleton } from '../../../../shared/hooks/TencerApiClientSingleton';
import CartMapper from '../../../../cart/infraestructure/repository/api/data-mapper/CartMapper';
import Cart from '../../../../cart/domain/model/Cart';
import { getCurrency } from '../../../../shared/getCurrency';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { Currency } from '../../../../shared/Currency';

export const CartPaymentCardScreen: React.FC = () => {
    const { t } = useTranslation();
    const pageName = 'cart_payment_card';
    const isMounted = useIsMounted();
    const navigate = useNavigate();

    const UseToken = useToken();
    const [cart, setCart] = React.useState<Cart | null>();

    const currency = getCurrency();

    useEffect(() => {
        const APIClient = TencerApiClientSingleton.getInstance();
        APIClient.getMyCart()
            .then((response) => {
                if (!isMounted) {
                    return;
                }
                setCart(CartMapper.fromResponse(response));
            });
    }, []);

    const UseCartPayment = useCartPayment({
        cart,
        userCredentialsId: UseToken.username ? new UserCredentialId(UseToken.username) : undefined,
    });

    const cancelPayment = ():void => {
        navigate(TencerRoutes.CART_SUMMARY);
    };

    return (
        <div>
            <div className={classNames('screen', `screen-${pageName}`)}>
                <div className={classNames('container', `container-${pageName}`, 'screen-container', 'l-modal')}>
                    <div className="header">
                        <div className="title">
                            <h1 className="section-title">{t(CREDIT_CARD_PAYMENT_TITLE)}</h1>
                        </div>
                        <div className="logo">
                            <LogoWowBlack />
                        </div>
                        <div className="amount">
                            <div className="total">
                                {
                                    cart ? <Currency value={cart.totalAmount.amount ?? 0.0} currency={currency} />
                                        : (<Skeleton inline width={70} />)
                                }
                            </div>
                            <div className="tax">{t('ORDER_TOTAL_AMOUNT_LABEL')}</div>
                        </div>
                    </div>
                    <div className="body">
                        {
                            UseCartPayment.clientSecret && UseCartPayment.stripePromise ? (
                                <CartPayment
                                    secret={UseCartPayment.clientSecret}
                                    stripePromise={UseCartPayment.stripePromise}
                                    amount={cart?.totalAmount}
                                    onReset={cancelPayment}
                                />
                            ) : <Skeleton height={300} />
                        }
                    </div>
                </div>
            </div>
        </div>

    );
};
