import { CollectionId } from '../value-object/CollectionId';
import { CollectionName } from '../value-object/CollectionName';
import { Image } from '../value-object/Image';
import { ProductFormat } from '../value-object/ProductFormat';
import { ProductId } from '../value-object/ProductId';
import { ProductPrice } from '../value-object/ProductPrice';

export class Packing {

    public constructor(
        private _collectionId:CollectionId | null,
        private _productId:ProductId,
        private _image: Image | null = null,
        private _collectionName: CollectionName | null = null,
        private _format: ProductFormat | null = null,
        private _description: string | null = null,
        private _boxMeter: number | null = null,
        private _boxMeterL: number | null = null,
        private _boxPiece: number | null = null,
        private _boxWeight: number | null = null,
        private _default: boolean | null = null,
        private _palletBox: number | null = null,
        private _palletPlane: number | null = null,
        private _heights: number | null = null,
        private _palletPiece: number | null = null,
        private _palletMeter: number | null = null,
        private _family: string | null = null,
        private _type: string | null = null,
        private _outlet: boolean = false,
        private _stockEntryDate: Date | null = null,
        private _tile: string | null = null,
        private _stock: number | null = null,
        private _code: string | null = null,
        private _unit: string,
        private _paste: string | null = null,
        private _enabled: boolean = true,
        private _price: ProductPrice | null = null,
    ) {}

    public get description(): string | null {
        return this._description;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public get paste(): string | null {
        return this._paste;
    }
    public get unit(): string {
        return this._unit;
    }

    public get code(): string | null {
        return this._code;
    }

    public get family(): string | null {
        return this._family;
    }
    public get type(): string | null {
        return this._type;
    }

    public get outlet(): boolean {
        return this._outlet;
    }
    public get stockEntryDate(): Date | null {
        return this._stockEntryDate;
    }
    public get tile(): string | null {
        return this._tile;
    }
    public get stock(): number | null {
        return this._stock;
    }

    public get default(): boolean | null {
        return this._default;
    }
    public get palletBox(): number | null {
        return this._palletBox;
    }
    public get palletPlane(): number | null {
        return this._palletPlane;
    }
    public get heights(): number | null {
        return this._heights;
    }
    public get palletPiece(): number | null {
        return this._palletPiece;
    }
    public get palletMeter(): number | null {
        return this._palletMeter;
    }

    public get boxMeter(): number | null {
        return this._boxMeter;
    }

    public get boxMeterL(): number | null {
        return this._boxMeterL;
    }

    public get boxPiece(): number | null {
        return this._boxPiece;
    }

    public get boxWeight(): number | null {
        return this._boxWeight;
    }

    public get collectionId(): CollectionId | null {
        return this._collectionId;
    }

    public get collectionName(): CollectionName | null {
        return this._collectionName;
    }

    public get image():Image | null {
        return this._image;
    }

    public set image(value: Image | null) {
        this._image = value;
    }

    public get format():ProductFormat | null {
        return this._format;
    }

    public set format(value: ProductFormat | null) {
        this._format = value;
    }

    public get productId(): ProductId {
        return this._productId;
    }

    public get price(): ProductPrice | null {
        return this._price;
    }

}
